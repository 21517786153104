.button {
  background-color: #4f575c;
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #3f464a;
  }

  &:disabled {
    background-color: #4f575c94;
    cursor: wait;
  }

  .icon {
    position: relative;
    top: 2px;
    left: 5px;
  }
}
