$primary-color: #ffffff;
$border-color: #ececec;

.client-upload-card {
  .brand-card-container {
    width: 300px;
    padding-bottom: 10px;
    border: solid darken($color: $border-color, $amount: 0) 1px;
    border-radius: 5px;
    box-shadow: 0px 5px 10px 0px #ececec;

    .image-container {
      padding: 10px;
      border-bottom: solid #ececec 1px;
      text-align: center;

      .previewer {
        object-fit: contain;
        max-width: 100%;
      }

      .dropzone {
        height: 150px;
        border: dashed $border-color 3px;
        border-radius: 5px;
        text-align: center;

        .dropzone-text {
          padding: 20px;
        }
      }
    }

    .upload-button {
      margin-top: 20px;
    }

    .loading-container {
      height: 150px;
    }

    .cancel-button {
      margin-top: 20px;
      margin-left: 20px;
    }

    .delete-button {
      margin-top: 20px;
    }
  }
}
