body {
  margin: 0;
  padding: 0;
  font-family: "Exo 2", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navigationBar {
  height: 50px;
  background-color: white;
  box-shadow: 0 5px 25px -9px rgba(0, 0, 0, 0.75);
  top: 0;
  align-items: center; }
  .navigationBar.fixed-top {
    position: fixed;
    z-index: 1030;
    right: 0;
    left: 0; }
  .navigationBar .logout {
    padding: 10px; }
  .navigationBar .logo {
    width: 180px;
    height: 30px;
    background: url(/hgem-logo-horizontal.png) center left no-repeat;
    background-size: contain;
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-left: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap; }
  .navigationBar .text-end {
    text-align: right; }

.alert {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .alert.alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.centered-text {
    text-align: center; }
  .alert .clear-alert {
    cursor: pointer;
    float: right; }

.button {
  background-color: #4f575c;
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer; }
  .button:hover {
    background-color: #3f464a; }
  .button:disabled {
    background-color: #4f575c94;
    cursor: wait; }
  .button .icon {
    position: relative;
    top: 2px;
    left: 5px; }

.client-upload-card .brand-card-container {
  width: 300px;
  padding-bottom: 10px;
  border: solid #ececec 1px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 0px #ececec; }
  .client-upload-card .brand-card-container .image-container {
    padding: 10px;
    border-bottom: solid #ececec 1px;
    text-align: center; }
    .client-upload-card .brand-card-container .image-container .previewer {
      object-fit: contain;
      max-width: 100%; }
    .client-upload-card .brand-card-container .image-container .dropzone {
      height: 150px;
      border: dashed #ececec 3px;
      border-radius: 5px;
      text-align: center; }
      .client-upload-card .brand-card-container .image-container .dropzone .dropzone-text {
        padding: 20px; }
  .client-upload-card .brand-card-container .upload-button {
    margin-top: 20px; }
  .client-upload-card .brand-card-container .loading-container {
    height: 150px; }
  .client-upload-card .brand-card-container .cancel-button {
    margin-top: 20px;
    margin-left: 20px; }
  .client-upload-card .brand-card-container .delete-button {
    margin-top: 20px; }

.client-logo-upload-page .label-text {
  line-height: 32px; }

.client-logo-upload-page .select-container {
  max-width: 500px;
  margin-bottom: 20px; }

.page {
  margin-top: 55px;
}
