.client-logo-upload-page {
  .label-text {
    line-height: 32px;
  }

  .select-container {
    max-width: 500px;
    margin-bottom: 20px;
  }
}
