.navigationBar {
  height: 50px;
  background-color: white;
  box-shadow: 0 5px 25px -9px rgba(0, 0, 0, 0.75);
  top: 0;
  align-items: center;

  &.fixed-top {
    position: fixed;
    z-index: 1030;
    right: 0;
    left: 0;
  }

  .logout {
    padding: 10px;
  }

  .logo {
    width: 180px;
    height: 30px;
    background: url(/hgem-logo-horizontal.png) center left no-repeat;
    background-size: contain;
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-left: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }

  .text-end {
    text-align: right;
  }
}
