.alert {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &.alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &.centered-text {
    text-align: center;
  }

  .clear-alert {
    cursor: pointer;
    float: right;
  }
}
